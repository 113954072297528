import React, { FC, ReactElement, memo, useState, useEffect } from 'react';
import { Button, Form, Input, message, Select, Switch, DatePicker } from 'antd';
import styled from 'styled-components';
import { useStores } from 'store/useStore';
import Server from 'utils/serviceApi';
import Modals from 'components/Modal';
import moment from 'moment';
import zhCN from 'antd/es/date-picker/locale/zh_CN';
const { Option } = Select;

const ContView = styled.div`
    max-height: 60vh;
    /* overflow-y: auto; */
`;
const FromButtonView = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 20px;
    button {
        width: 200px;
    }
`;

const formItemLayout = {
    labelCol: {
        xs: { span: 12 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
    },
};

interface IProps {
    visible: boolean;
    typeList: any;
    initInfo: any;
    handleCancel: (isUpdate: boolean) => void;
}

const AddModal: FC<IProps> = (props): ReactElement => {
    const { visible, typeList, initInfo, handleCancel } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const { walletStore } = useStores();
    const [initValues, setInitValues] = useState({});

    const [form] = Form.useForm();
    const submit = () => {
        try {
            form.validateFields().then(async res => {
                const values = form.getFieldsValue();
                const params = {
                    ...values,
                    id: initInfo.id,
                };
                const result: any = await Server.productUpdate(params);
                if (result.code !== 0) throw new Error(result.msg);
                message.success('修改成功');
                onCancel(true);
            });
        } catch (e: any) {
            message.error(e.message);
        }
    };

    const onCancel = (isUpdate: boolean = false) => {
        form.resetFields();
        setLoading(false);
        handleCancel(isUpdate);
    };

    useEffect(() => {
        if (Object.keys(initInfo).length > 0) {
            form.setFieldsValue({ ...initInfo, onShelf: initInfo.onShelf === 1 ? '1' : '0'  });
            // form.setFieldsValue({ ...initInfo, userRole: initInfo.userRole === '普通会员' ? '' : initInfo.userRole, vipDeadline: initInfo.vipDeadline ? moment(initInfo.vipDeadline, 'YYYY-MM-DD hh:mm:ss') : '' });
            setInitValues({ ...initInfo, onShelf: initInfo.onShelf === 1 ? '1' : '0' });
            // setInitValues({ ...initInfo, userRole: initInfo.userRole === '普通会员' ? '' : initInfo.userRole, vipDeadline: moment(initInfo.vipDeadline, 'YYYY-MM-DD hh:mm:ss'), blacklist: !!initInfo.blacklist, vip: !!initInfo.vip });
        }
    }, [initInfo]);

    return (
        <Modals visible={visible} maskCloseable={false} onCancel={() => onCancel()} title={'编辑套餐信息'}>
            <ContView>
                <Form size="large" {...formItemLayout} form={form} initialValues={initValues} name="advanced_search" className="ant-advanced-search-form">
                    {/* <Form.Item
                        label="状态"
                        name="onShelf"
                        rules={[
                            {
                                required: true,
                                message: '请输入',
                            },
                        ]}
                    >
                        <Switch checkedChildren="上架" unCheckedChildren="下架" />
                    </Form.Item> */}

                    <Form.Item
                        label="金额(单位:分)"
                        name="totalAmount"
                        rules={[
                            {
                                required: true,
                                message: '请输入金额',
                            },
                        ]}
                    >
                        <Input placeholder="请输入次数" />
                    </Form.Item>
                    <Form.Item
                        label="积分"
                        name="totalScore"
                        rules={[
                            {
                                required: true,
                                message: '请输入积分',
                            },
                        ]}
                    >
                        <Input placeholder="请输入积分" />
                    </Form.Item>
                    <Form.Item
                        label="原价"
                        name="priceNormal"
                        rules={[
                            {
                                required: true,
                                message: '请输入原价',
                            },
                        ]}
                    >
                        <Input placeholder="请输入原价" />
                    </Form.Item>
                    <Form.Item
                        label="折扣价"
                        name="priceDiscount"
                        rules={[
                            {
                                required: true,
                                message: '请输入折扣价',
                            },
                        ]}
                    >
                        <Input placeholder="请输入折扣价" />
                    </Form.Item>
                    <Form.Item
                        label="描述"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: '请输入描述',
                            },
                        ]}
                    >
                        <Input placeholder="请输入描述" />
                    </Form.Item>
                    
                    <Form.Item label="状态" name="onShelf" rules={[
                            {
                                required: true,
                                message: '请输入',
                            },
                        ]}>
                        <Select placeholder="请选择状态">
                            <Option value="1">上架</Option>
                            <Option value="0">下架</Option>
                        </Select>
                    </Form.Item>

                    {/* <Form.Item label="会员时长" name="vipDeadline">
                        <DatePicker locale={zhCN} placeholder="请选择时间" showTime />
                    </Form.Item> */}

                    <FromButtonView>
                        <Button type="primary" loading={loading} onClick={submit}>
                            提交
                        </Button>
                    </FromButtonView>
                </Form>
            </ContView>
        </Modals>
    );
};

export default memo(AddModal);
