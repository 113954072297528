import React, { FC, ReactElement, memo, useState, useEffect } from 'react';
import { Button, Form, Input, message, Select, Switch, DatePicker } from 'antd';
import styled from 'styled-components';
import { useStores } from 'store/useStore';
import Server from 'utils/serviceApi';
import Modals from 'components/Modal';
import moment from 'moment';
import zhCN from 'antd/es/date-picker/locale/zh_CN';
const { Option } = Select;

const ContView = styled.div`
    max-height: 60vh;
    /* overflow-y: auto; */
`;
const FromButtonView = styled.div`
    display: flex;
    justify-content: center;
    padding: 0 20px;
    button {
        width: 200px;
    }
`;

const formItemLayout = {
    labelCol: {
        xs: { span: 12 },
        sm: { span: 7 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
    },
};

interface IProps {
    visible: boolean;
    typeList: any;
    initInfo: any;
    handleCancel: (isUpdate: boolean) => void;
}

const AddModal: FC<IProps> = (props): ReactElement => {
    const { visible, typeList, initInfo, handleCancel } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const { walletStore } = useStores();
    const [initValues, setInitValues] = useState({});

    const [form] = Form.useForm();
    const submit = () => {
        try {
            form.validateFields().then(async res => {
                const values = form.getFieldsValue();
                const params = {
                    ...values,
                    id: initInfo.id,
                    blacklist: values.blacklist ? 1 : 0,
                    vip: values.vip ? 1 : 0,
                    userRole: values.vip ? values.userRole : '普通会员',
                    vipDeadline: values.vip ? moment(values.vipDeadline).valueOf() : '',
                };
                if (params.vip === 1 && !params.userRole) {
                    return message.error('请选择身份');
                }
                if (params.vip === 1 && (!params.vipDeadline || params.vipDeadline === 'NaN')) {
                    return message.error('请选择会员时长');
                }
                const result: any = await Server.update(params);
                if (result.code !== 0) throw new Error(result.msg);
                message.success('修改成功');
                onCancel(true);
            });
        } catch (e: any) {
            message.error(e.message);
        }
    };

    const onCancel = (isUpdate: boolean = false) => {
        form.resetFields();
        setLoading(false);
        handleCancel(isUpdate);
    };

    // useEffect(() => {
    //     if (Object.keys(initInfo).length > 0) {
    //         form.setFieldsValue({ ...initInfo, vipDeadline: initInfo.vipDeadline ? moment(initInfo.vipDeadline, 'YYYY-MM-DD hh:mm:ss') : '' });
    //         setInitValues({...initInfo, vipDeadline: moment(initInfo.vipDeadline, 'YYYY-MM-DD hh:mm:ss'), blacklist: !!initInfo.blacklist, vip: !!initInfo.vip })
    //     }
    // }, [initInfo]);

    useEffect(() => {
        if (Object.keys(initInfo).length > 0) {
            form.setFieldsValue({ ...initInfo });
            // form.setFieldsValue({ ...initInfo, userRole: initInfo.userRole === '普通会员' ? '' : initInfo.userRole, vipDeadline: initInfo.vipDeadline ? moment(initInfo.vipDeadline, 'YYYY-MM-DD hh:mm:ss') : '' });
            setInitValues({ ...initInfo });
            // setInitValues({ ...initInfo, userRole: initInfo.userRole === '普通会员' ? '' : initInfo.userRole, vipDeadline: moment(initInfo.vipDeadline, 'YYYY-MM-DD hh:mm:ss'), blacklist: !!initInfo.blacklist, vip: !!initInfo.vip });
        }
    }, [initInfo]);

    return (
        <Modals visible={visible} maskCloseable={false} onCancel={() => onCancel()} title={'编辑用户信息'}>
            <ContView>
                <Form size="large" {...formItemLayout} form={form} initialValues={initValues} name="advanced_search" className="ant-advanced-search-form">
                    <Form.Item
                        label="黑名单"
                        name="blacklist"
                        rules={[
                            {
                                required: true,
                                message: '请输入',
                            },
                        ]}
                    >
                        <Switch checkedChildren="开启" unCheckedChildren="关闭" />
                    </Form.Item>

                    <Form.Item
                        label="免费次数"
                        name="freeTimes"
                        rules={[
                            {
                                required: true,
                                message: '请输入次数',
                            },
                        ]}
                    >
                        <Input placeholder="请输入次数" />
                    </Form.Item>

                    <Form.Item
                        label="会员"
                        name="vip"
                        valuePropName="checked"
                        rules={[
                            {
                                required: true,
                                message: '请输入',
                            },
                        ]}
                    >
                        <Switch checkedChildren="是" unCheckedChildren="否" />
                    </Form.Item>
                    <Form.Item label="身份" name="userRole">
                        <Select placeholder="请选择身份">
                            <Option value="推广代理">推广代理</Option>
                            <Option value="普通会员">普通会员</Option>
                        </Select>
                    </Form.Item>

                    {/* <Form.Item label="会员时长" name="vipDeadline">
                        <DatePicker locale={zhCN} placeholder="请选择时间" showTime />
                    </Form.Item> */}

                    <FromButtonView>
                        <Button type="primary" loading={loading} onClick={submit}>
                            提交
                        </Button>
                    </FromButtonView>
                </Form>
            </ContView>
        </Modals>
    );
};

export default memo(AddModal);
