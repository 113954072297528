import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Menu } from 'antd';
import { StrikethroughOutlined, DashboardOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;

const rendChildren: React.FC = (data: Record<string, any>) => {
    return (
        <SubMenu key={data.name} icon={data.icon} title={data.name}>
            {data.children.map((item: any) => {
                if (item?.children && item.children.length > 0) {
                    return rendChildren(data.children);
                } else {
                    return <Menu.Item key={item.path}> {item.name}</Menu.Item>;
                }
            })}
        </SubMenu>
    );
};

interface IProps {
    collapsed: boolean;
}
const LayoutMenu: React.FC<IProps> = ({ collapsed }) => {
    const location = useLocation(),
        navigate = useNavigate();
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const menuList: Record<string, unknown>[] = [
        {
            name: '用户列表',
            path: '/user',
            icon: <UserOutlined />,
        },
        {
            name: '套餐列表',
            path: '/product',
            icon: <StrikethroughOutlined />,
        },
    ];

    // useEffect(() => {
    //     setSelectedKeys(['/nft-open-json']);
    //     setOpenKeys(['IPFS']);
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dealDeep(menuList);
    }, [location.pathname]);

    const dealDeep = (list: any[], parentData?: any) => {
        list.forEach((data: any) => {
            if (data.path === location.pathname) {
                setSelectedKeys([location.pathname]);
                parentData && setOpenKeys([parentData.name]);
                return;
            } else if (data?.children && data.children.length > 0) {
                dealDeep(data.children, data);
            }
        });
    };

    return (
        <Menu
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            mode="inline"
            inlineCollapsed={collapsed}
            onOpenChange={data => {
                setOpenKeys(data as string[]);
            }}
            onClick={data => {
                setSelectedKeys([data.keyPath[0]]);
                navigate(data.key);
            }}
        >
            {menuList.map((item: any, index: number) => {
                if (item?.children && item.children.length > 0) {
                    return rendChildren(item);
                } else {
                    return (
                        <Menu.Item key={item.path} icon={item.icon}>
                            {item.name}
                        </Menu.Item>
                    );
                }
            })}
        </Menu>
    );
};

export default LayoutMenu;
