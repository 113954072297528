import {Route, Routes, Navigate, BrowserRouter, useNavigate, HashRouter} from 'react-router-dom';

import React, {useEffect, useState} from 'react';

import Layout from 'components/layout';

import {Provider} from 'react-redux';
import {useSelector} from 'react-redux';
import store, {AppState} from './state';

import {ThemeProvider} from 'styled-components';
import {drak, light} from './theme';

import ToastsProvider from 'contexts/toast';
import Login from './page/login';

// admin page
import User from './page/user';
import Invite from './page/invite';
import Product from './page/product';

import { useUserInfo } from '@/hooks';

const BaseLayoutRoute: React.FC = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/user" element={<User/>}/>
                <Route path="/invite" element={<Invite/>}/>
                <Route path="/product" element={<Product/>}/>
                {/* <Route path={'*'} element={<Navigate to="/login"/>}/> */}
            </Routes>
        </Layout>
    );
};

const Providers: React.FC = ({children}) => {
    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};

const StyledThemeProvider = props => {
    const baseInfo = useSelector((state: AppState) => state.base);
    let navigate = useNavigate();
    useUserInfo();


    useEffect(() => {
        window.$navigate = navigate;
    }, []);
    return <ThemeProvider theme={baseInfo.themeMode === 'light' ? light : drak} {...props} />;
};

const Entry: React.ComponentType = () => {
    return (
        <Providers>
            <BrowserRouter>
                <StyledThemeProvider>
                    <ToastsProvider>
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/*" element={<BaseLayoutRoute/>}/>
                            {/* <Route path={'*'} element={<Navigate to="/login"/>}/> */}
                        </Routes>
                    </ToastsProvider>
                </StyledThemeProvider>
            </BrowserRouter>
        </Providers>
    );
};
export default Entry;
