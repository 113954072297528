import { FC, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';

type Branch = {
    top: number;
    list: any[];
}

interface IProps {
    itemHeight: number;  // 元素高度
    showLength: number;  // 展示的数据长度
    rows: number;   // 每行展示的数量
}

const Scroll: FC<IProps> = ({itemHeight, showLength, rows}: IProps): ReactElement => {
    // const itemHeight = 200, showLength = 30, rows = 3;
    const [list, setList] = useState<any[]>([]);
    const [count, setCount] = useState<number>(0);
    const [branch, setBranch] = useState<Branch>({
        top: 0,
        list: []
    });
    
    useEffect(() => {
        getMockItems();
        return () => {};
    }, []);

    const getMockItems = () => {
        const length: any = { length: 200 };
        const _data = Array.apply(null, length).map((value, index) => index);
        
        setList([..._data]);
        setCount(0);
        setBranch({
            top: 0,
            list: _data.splice(count * showLength, showLength)
        });
    };

    const scrollEvent = (event: any) => {
            const target = event.target;
            const bottomDistance = 400;
            const ChildLength = target.children.length;
            // const _target = target.children[ChildLength - rows]; // 倒数第N(rows)个子元素露出时再次加载
            const _target = target.children[ChildLength - rows * 2]; // 倒数第N(rows * 2)个子元素露出时再次加载
            const _targetToTop = _target.getBoundingClientRect().top; 
            if(target.scrollTop + target.clientHeight >= _targetToTop + branch.top){
                calcDown() 
            }

            if(target.scrollTop <= branch.top + bottomDistance ){
                calcUp()
            }
    };

    const calcDown = () => {
        let _count = count + 1, _show = [];
        if(_count * showLength > list.length) return;
        const _jack = branch.list.splice(branch.list.length - showLength, showLength);
        _show = [..._jack, ...[...list].splice(_count * showLength, showLength)];
        if(count >= 1){
            setBranch({
                top: showLength / rows * itemHeight * count,
                list: _show
            }) 
        }else{
            setBranch({
                top: 0,
                list: _show
            }) 
        }
        setCount(_count);
    };

    const calcUp = () => {
        if(count > 1){
            let _count = count - 1;
            let _prevList = [...list].splice((_count - 1) * showLength, showLength);
            let _moreList = [...branch.list].splice(0, showLength);
            setBranch({
                top: showLength / rows * itemHeight * (count - 2),
                // top: branch.top - 20 * 200,
                list: [..._prevList, ..._moreList]
            })
            setCount(_count);
        }
    };

    return (
        <ContMain>
            <ScrollContent className="_scroll_content" onScroll={scrollEvent} top={branch.top}>
                {branch.list.map((item, index) => (
                    <List key={item +'--'+ index} name={item} type={index < 29 ? 'frist' : 'more'} data={item}>
                        {item}---frist---{count}--{branch.top}
                    </List>
                ))}
            </ScrollContent>
        </ContMain>
    );
};

const ContMain = styled.div`
    width: 100%;
    height: 100%;
    background: blue;
    padding: 200px 0 200px;
`;
const ScrollContent = styled.div.attrs((props:any) => ({top: props.top}))`
    width: 80%;
    height: 100%;
    overflow-y: auto;
    background: red;
    background: #f0f2f5;
    background-size: 100%;
    color: rgba(0, 0, 0, 0.65);
    margin: 0 auto;
    &._scroll_content::before {
        content: '';
        display: block;
        height: ${props => props.top + 'px'}
    }
`;
const List = styled.div.attrs((props: any) => ({ data: props.data, name: props.name, type: props.type }))`
    width: calc(100% / 3);
    display: inline-block;
    text-align: center;
    line-height: 200px;
    // background: rgb(${props => Math.floor(Math.random() * 255 - Math.random() * props.data)}, ${props => Math.floor(Math.random() * 255 - Math.random() * props.data)}, ${props => Math.floor(Math.random() * 255 - Math.random() * props.data)});
    // background: #eee;
    // border: 1px solid red;
`;

export default Scroll;
Scroll.defaultProps = {
    itemHeight: 200,
    showLength: 30,
    rows: 3
}
