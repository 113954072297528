import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Btn = styled.div`
    min-width: 150px;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #1890ff;
    text-align: center;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    &.disabled {
        cursor: not-allowed;
        background: #a4a2ab !important;
    }
    &.loading::before {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        z-index: 1;
        background: #fff;
        border-radius: inherit;
        opacity: 0.35;
        transition: opacity 0.2s;
        font-size: 30px;
        content: '';
        pointer-events: none;
    }
`;
const LoadSvg = styled.span`
    display: inline-block;
    margin-right: 10px;
    animation: rotate 1s infinite linear;
`;

interface ButtonProps {
    loading?: boolean;
    disabled?: boolean;
    children?: React.ReactNode;
    className?: string;
    style?: Record<string, any>;
    onClick?: React.MouseEventHandler<HTMLElement>;
}

const Button: FC<ButtonProps> = ({ children, className, style, loading, disabled, onClick }): ReactElement => {
    return (
        <Btn className={cn(className, loading && 'loading', disabled && 'disabled')} style={style} onClick={!loading && !disabled ? onClick : () => {}}>
            {loading && (
                <LoadSvg>
                    <svg viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                    </svg>
                </LoadSvg>
            )}
            {children}
        </Btn>
    );
};

export default Button;
