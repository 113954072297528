import { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';

const Dialog = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
`;
const DialogBlock = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
`;
const DialogContent = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: #2b3049;
`;
const SelectTop = styled.div`
    width: 100%;
    line-height: 0.53rem;
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: #848fb5;
    font-size: 0.16rem;
    padding: 0 0.19rem;
    span:first-child {
        font-weight: 400;
        color: #7a96ff;
    }
`;
const ScrollView = styled.div`
    max-height: 3rem;
    padding-bottom: 0.5rem;
    overflow-y: auto;
`;
const LanguageLibs = styled.div`
    line-height: 0.44rem;
    text-align: center;
    font-size: 0.16rem;
    font-weight: 600;
    color: #ffffff;
    position: relative;
    i {
        position: absolute;
        right: 0.19rem;
        color: #7a96ff;
    }
    &.active {
        background: #21263e;
    }
`;

type ListObject = {
    [key: string]: any;
};
interface Props {
    list: ListObject[];
    activeKey: string | number;
    title?: string;
    onlyKey?: string;
    onOkTitle: string;
    isDefaultAll?: boolean;
    onOkCallback: (data: ListObject) => void;
    onCancelCallback: () => void;
    [key: string]: any;
}

const Select: FC<Props> = ({ activeKey, list, onOkTitle, title, isDefaultAll, onlyKey, onOkCallback, onCancelCallback }: Props): ReactElement => {
    const [activeindex, setActiveindex] = useState<number>(list.findIndex(item => item[onlyKey] === activeKey) + 1);

    const submit = (index) => {
        let _data = {};
        if(isDefaultAll){
            _data = index === 0 ? {
                [onlyKey]: '',
                [title]: '全部'
            } : list[index-1];
        }else{
            _data = list[index-1]
        } 
        setActiveindex(index);
        onOkCallback(_data);
    }
    return (
        <Dialog>
            <DialogBlock onClick={() => onCancelCallback()} />
            <DialogContent>
                <SelectTop>
                    <span>{onOkTitle ?? '确认'}</span>
                    <span onClick={() => onCancelCallback()}>取消</span>
                </SelectTop>
                <ScrollView>
                    {isDefaultAll && (
                        <LanguageLibs className={(activeindex === -1 ? activeKey === '' : activeindex === 0) && 'active'} onClick={() => submit(0)}>
                            全部
                            {(activeindex === -1 ? activeKey === '' : activeindex === 0) && <i className="iconfont">&#xe604;</i>}
                        </LanguageLibs>
                    )}

                    {list.map((item, index) => (
                        <LanguageLibs key={item[onlyKey]+'-'+index} className={(activeindex === -1 ? item[onlyKey] === activeKey : activeindex === index + 1) && 'active'} onClick={() => submit(index + 1)}>
                            {item[title]}
                            {(activeindex === -1 ? item[onlyKey] === activeKey : activeindex === index + 1) && <i className="iconfont">&#xe604;</i>}
                        </LanguageLibs>
                    ))}
                </ScrollView>
            </DialogContent>
        </Dialog>
    );
};

export default Select;
Select.defaultProps = {
    onlyKey: 'key',
    title: 'title',
    isDefaultAll: false,
};
