import React, { useEffect, useState } from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useUserInfo } from '@/hooks';
import { useObserver } from 'mobx-react';
import styles from './topbar.module.scss';
import styled from 'styled-components';


type Iprops = {
    showMenu: boolean;
    showMenuFN: (boolean) => void;
};

const TopBar: React.FC<Iprops> = ({ showMenu, showMenuFN }) => {
    const [userInfo] = useUserInfo();

    return useObserver(() => (
        <div className={styles.topbarHead}>
            <div>
                <span className={styles._h5Tit}>伽玛帐后台管理</span>
                <Icon onClick={() => showMenuFN(true)}>{showMenu ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}</Icon>
            </div>

            <div className={styles.topbarHeadRight}>
                <span>总量：{Number(userInfo?.totalBalance ?? 0)}$ </span>
                <span>已使用：{Number(userInfo?.used ?? 0)}$ </span>
                <div className={styles.topbarHeadRightChain}>
                    <img src='/images/user.svg' alt="" />
                    {userInfo?.accountName}
                </div>
            </div>
        </div>
    ));
};

const Icon = styled.span`
    display: none;
    @media (max-width: 768px) {
        display: inline-block;
        margin-left: 0;
    }
`;
export default TopBar;
