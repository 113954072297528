import React, {FC, ReactElement, ReactNode, memo} from 'react';
import cn from 'classnames';
import styles from './Modal.module.scss';
import {CloseOutlined} from '@ant-design/icons';

interface DialogProps {
    mask?: boolean;
    maskCloseable?: boolean;
    dialogClassName?: string;

    style?: Record<string, any>;
    title?: string;
    width?: string;
    visible: boolean;

    children: ReactNode;
    handleCancel: () => void;
    onCancel?: () => void;
}

const Dialogs: FC<DialogProps> = (props): ReactElement => {
    const {
        mask,
        maskCloseable,
        title,
        children,
        handleCancel,
        visible,
        width,
        dialogClassName,
        style
    } = props;

    return (
        <>
            {mask ? (
                <div
                    onClick={() => {
                        maskCloseable && handleCancel();
                    }}
                    className={cn(styles.mask, !visible && styles.mask_hidden_ani)}
                ></div>
            ) : null}

            <div
                className={cn(styles.modal, !visible && styles.close_modal, dialogClassName, 'nft-modal')} style={{width}}>
                {title ? (
                    <div className={cn(styles.title, 'nft-modal-title')}>
                        <div>{title}</div>
                    </div>
                ) : null}
                <div className={styles.closeIcon} onClick={handleCancel}>
                    <CloseOutlined/>
                </div>
                <div className={cn(styles.content, 'nft-modal-content')}>{children}</div>
            </div>
        </>
    );
};

Dialogs.defaultProps = {
    width: '600px',
};

export default memo(Dialogs);
