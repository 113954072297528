import React, { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Dialogs from './Dialogs';

interface ModalProps {
    mask?: boolean;
    maskCloseable?: boolean;
    dialogClassName?: string;

    title?: string;
    width?: string;
    visible: boolean;

    children: ReactNode;
    onCancel?: () => void;
}

const Modal: FC<ModalProps> = (props): ReactElement => {
    const { visible, onCancel } = props;
    const [rootNode, setRootNode] = useState(null);

    const handleCancel = () => {
        onCancel && onCancel();
    };

    useEffect(() => {
        // console.error('useEffect--------',props)
    });

    // 监听visible,和 根节点rootNode 渲染弹窗
    useEffect(() => {
        // console.error('----->')
        const get_rootNode = document.querySelector('#modal_wrap');

        if (visible) {
            if (!get_rootNode) {
                const modal_wrap = document.createElement('div');
                modal_wrap.setAttribute('id', 'modal_wrap');
                document.body.appendChild(modal_wrap);
                setRootNode(modal_wrap);
            } else {
                setRootNode(get_rootNode);
            }
        } else {
            // 弹窗消失，等消失动画完成，将根节点清除

            if (rootNode) {
                const timer = setTimeout(() => {
                    setRootNode(null);
                    clearTimeout(timer);
                }, 400);
            } else {
                // ReactDOM.unmountComponentAtNode(rootNode);
                // rootNode && document.body.removeChild(rootNode);
                // 根节点清除后，移除dom
                const get_rootNode = document.querySelector('#modal_wrap');
                get_rootNode && document.body.removeChild(get_rootNode);
            }
        }
    }, [rootNode, visible]);

    return rootNode ? ReactDOM.createPortal(<Dialogs {...props} handleCancel={handleCancel} />, rootNode) : null;
};

Modal.defaultProps = {
    maskCloseable: true,
    mask: true,
};

// export default memo(Modal)
export default Modal;
