import axios from 'axios';

type methods = 'delete' | 'post' | 'get' | 'put';

interface axiosParams {
    url: string;
    params?: Record<string, any>;
    data?: Record<string, any>;
    submitFrom?: boolean;
    method: methods;
}

let axiosIns: any = axios.create({
    baseURL: process.env.REACT_APP_URL,
    // baseURL: '/',
    withCredentials: true, //accept cookie
});

axios.defaults.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
};

axiosIns.defaults.responseType = 'json';

axiosIns.defaults.validateStatus = (status: number) => {
    return true;
};
axiosIns.interceptors.request.use((config: any) => {
    config['timeout'] = 60 * 1000 * 10;
    config.headers['token'] = localStorage.getItem('token');
    // if (config?.submitFrom) {
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    // config.headers['Content-Type'] = 'multipart/form-data';
    // }
    return config;
});

axiosIns.interceptors.response.use((response: any) => {
    let data = response.data;
    let status = response.status;
    if (data && status >= 200 && status < 300) {
        if (data.code === 401) {
            window.$navigate('/login');
            localStorage.clear();
            return Promise.resolve(response);
        }
        return Promise.resolve(response);
    } else {
        return Promise.reject(response);
    }
});

let ajaxMethod = ['get', 'post', 'put', 'delete'];
let api: any = {};
let timeout = {
    timeout: 12000,
};

ajaxMethod.forEach(method => {
    api[method] = (uri: string, data: object, config: object) => {
        return new Promise(function (resolve, reject) {
            axiosIns[method](uri, data, config)
                .then((response: any) => {
                    console.log('response:', response);
                    if (response?.status && response.status >= 200 && response.status < 300) {
                        resolve(response.data);
                    } else {
                        if (response instanceof Error) {
                            // window.$navigate('/login');
                        }
                        reject(response);
                    }
                })
                .catch((response: any) => {
                    //network issue
                    if (response instanceof Error) {
                        // window.$navigate('/login');
                    }
                    reject(response);
                });
        });
    };
});

let axiosService = (params: axiosParams): Promise<any> => {
    if (params.method === 'get') {
        return new Promise((resolve, reject) => {
            api.get(params.url, {params: params.params}, {...timeout, ...params})
                .then((res: any) => {
                    resolve(res);
                })
                .catch((res: any) => {
                    reject(res);
                });
        });
    } else if (params.method === 'delete') {
        return new Promise((resolve, reject) => {
            api.delete(params.url, params.data, {...timeout, ...params})
                .then((res: any) => {
                    resolve(res);
                })
                .catch((res: any) => {
                    reject(res);
                });
        });
    } else if (params.method === 'put') {
        return new Promise((resolve, reject) => {
            api.put(params.url, params.data, {...timeout, ...params})
                .then((res: any) => {
                    resolve(res);
                })
                .catch((res: any) => {
                    reject(res);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            api.post(params.url, params?.data ?? params.params, {...timeout, ...params})
                .then((res: any) => {
                    resolve(res);
                })
                .catch((res: any) => {
                    reject(res);
                });
        });
    }
};

export default axiosService;
