import { useState, useEffect } from 'react';

function useDebounce(defalut_delay: number = 1500): [(FN?: Function, delay?: number) => Promise<void>] {
    const [timer, setTimer] = useState<any>('');

    const debounce = (FN: Function, delay: number = defalut_delay): Promise<void> => {
        return new Promise((resolve, reject) => {
            clearInterval(timer);
            const _timer = setTimeout(() => {
                FN && FN();
                resolve()
            }, delay);
            setTimer(_timer);
        })
    };

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    }, []);
    return [debounce]
}

export default useDebounce;
