import { createContext, FC, ReactElement, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ToastContextApi, ToasApi, toastTypes } from './type';
import { IconError, IconWarning, IconSuccess } from 'components/Icon'

export const ToastsContext = createContext<ToastContextApi>(undefined);
const Toast: FC = (props): ReactElement => {
    const [toasts, setToasts] = useState<ToastContextApi['toasts']>([]);
    const times = 4500;
    useEffect(() => {
        // setToasts(prevToasts => {
        //     return [
        //         {
        //             id: 1,
        //             title: '我是error',
        //             type: 'error',
        //             description: '我是error我是error我是error我是error我是error我是error我是error',
        //         },
        //         {
        //             id: 2,
        //             title: '我是success',
        //             type: 'success',
        //             description: '我是success我是success我是success我是success我是success我是success我是success',
        //         },
        //         {
        //             id: 3,
        //             title: '我是info',
        //             type: 'info',
        //             description: '我是info我是info我是info我是info我是info我是info我是info我是info我是info我是info',
        //         },
        //         {
        //             id: 4,
        //             title: '我是warning',
        //             type: 'warning',
        //             description: '我是warning我是warning我是warning我是warning我是warning我是warning我是warning',
        //         },
        //         ...prevToasts,
        //     ];
        // });
    }, []);

    const toast = useCallback(
        ({ title, description, duration, id, type }) => {
            setToasts(prevToasts => {
                // Remove any existing toasts with the same id
                const currentToasts = prevToasts.filter(prevToast => prevToast.id !== id);
                // console.error('----', duration, duration === null)
                duration = duration === null ? null : duration || times
                
                autoHide(id, duration);
                return [
                    ...currentToasts,
                    {
                        id,
                        title,
                        description,
                        duration,
                        type,
                    },
                ];
            });
        },
        [setToasts]
    );

    // 可以通过动画来做隐藏向上消失的效果
    const autoHide = (id: string | number, duration: ToasApi['duration']) => {
        if(!duration) return;
        setTimeout(() => {
            setToasts(prevToasts => {
                // Remove any existing toasts with the same id
                const currentToasts = prevToasts.filter(prevToast => prevToast.id !== id);
                return [
                    ...currentToasts,
                ];
            });
        }, duration+1500)
    };
    const toastError = (title: ToasApi['title'], description?: ToasApi['description'], duration: ToasApi["duration"] = times) => {
        const id = new Date().getTime().toString();
        toast({ title, description, duration, id, type: toastTypes.ERROR });
        return id
    };
    const toastInfo = (title: ToasApi['title'], description?: ToasApi['description'], duration: ToasApi["duration"] = times) => {
        const id = new Date().getTime().toString();
        toast({ title, description, duration, id, type: toastTypes.INFO });
        return id
    };
    const toastSuccess = (title: ToasApi['title'], description?: ToasApi['description'], duration: ToasApi["duration"] = times) => {
        const id = new Date().getTime().toString();
        toast({ title, description, duration, id, type: toastTypes.SUCCESS });
        return id
    };
    const toastWarning = (title: ToasApi['title'], description?: ToasApi['description'], duration: ToasApi["duration"] = times) => {
        const id = new Date().getTime().toString();
        toast({ title, description, duration, id, type: toastTypes.WARNING });
        return id
    };
    const clear = () => setToasts([]);
    const remove = (id: string | number) => {
        setToasts(prevToasts => prevToasts.filter(prevToast => prevToast.id !== id));
    };

    const getIcon = (type) => {
        switch( type){
            case toastTypes.SUCCESS: return <IconSuccess color="#82D466" />;
            case toastTypes.ERROR: return <IconSuccess color="#FC333F" />;
            case toastTypes.WARNING: return <IconSuccess color="#F79D13" />;
            default: return ;
        }
    }

    return (
        <ToastsContext.Provider value={{ toasts, clear, remove, toastError, toastInfo, toastSuccess, toastWarning }}>
            {props.children}
            <Content>
                {toasts.map((item, index) => {
                    return (
                        <ToastModal key={item.id + '_' + index} duration={item.duration}>
                            <p>{getIcon(item.type)}{index}duration:{item.duration}--{item.id}==={item.title}-{item.type}</p>
                            {/* <p><IconSuccess color="#FC333F" />{index}duration:{item.duration}--{item.id}==={item.title}-{item.type}</p>
                            <p><IconSuccess color="#F79D13" />{index}duration:{item.duration}--{item.id}==={item.title}-{item.type}</p> */}
                        </ToastModal>
                    );
                })}
            </Content>
        </ToastsContext.Provider>
    );
};

const ToastModal = styled.div.attrs((props:any) => ({duration: props.duration}))`
    width: 100%;
    padding: 10px 20px;
    line-height: 1.57;
    background-color: #fff;
    color: #000;
    font-size: 14px;
    text-align: center;
    border-radius: 2px;
    margin-bottom: 20px;
    transition: all 0.3s;
    opacity: 1;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    pointer-events: all;
    animation: ${ (props: any) => props.duration === null ? '' : `hide 1.5s linear ${props.duration / 1000}s forwards`};
    // &.hide {
    //     animation: ${ (props: any) => props.duration === null ? '' : `hide 1.5s linear ${props.duration / 1000}s forwards`};
    // }
    @keyframes hide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
`;
const Content = styled.div`
    width: 40%;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
`;
export default Toast;
