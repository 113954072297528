import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    themeMode: 'light',
    language: 'en_US',

    userInfo: {}
};

const BaseInfoSlice = createSlice({
    name: 'base',
    initialState,
    reducers: {
        setUserInfo(state, { payload: info }) {
            state.userInfo = info;
        },
        setTheme(state, { payload: theme }) {
            state.themeMode = theme;
        },
        setLanguage(state, { payload: language }) {
            state.language = language;
        },
        
    },
});

export default BaseInfoSlice.reducer;
