import axiosService from '../axios';
import { loginParams } from './typing';

class Server {
    static meta = {
        baseUrl: '',
    };

    // login
    public static login<T>(data: loginParams): Promise<T> {
        return axiosService({
            url: `${this.meta.baseUrl}/login`,
            method: 'post',
            data,
        });
    }

    // logout
    public static logout<T>(): Promise<T> {
        return axiosService({
            url: `${this.meta.baseUrl}/logout`,
            method: 'post',
        });
    }

    // userList
    public static userList<T>(data): Promise<T> {
        return axiosService({
            url: `${this.meta.baseUrl}/api/userList`,
            method: 'post',
            data,
        });
    }

    // indirectUser
    public static indirectUser<T>(data): Promise<T> {
        return axiosService({
            url: `${this.meta.baseUrl}/api/indirectUser`,
            method: 'post',
            data,
        });
    }

    // update
    public static update<T>(data): Promise<T> {
        return axiosService({
            url: `${this.meta.baseUrl}/api/update`,
            method: 'post',
            data,
        });
    }

    // userList
    public static userinfo<T>(): Promise<T> {
        return axiosService({
            url: `${this.meta.baseUrl}/gpt/accInfo`,
            method: 'get',
        });
    }

    public static stat<T>(params): Promise<T> {
        return axiosService({
            url: `${this.meta.baseUrl}/api/stat`,
            method: 'get',
            params,
        });
    }
    
    public static product<T>(): Promise<T> {
        return axiosService({
            url: `${this.meta.baseUrl}/product/list`,
            method: 'get'
        });
    }

    public static productUpdate<T>(data): Promise<T> {
        return axiosService({
            url: `${this.meta.baseUrl}/product/update`,
            method: 'post',
            data
        });
    }
}

export default Server;
