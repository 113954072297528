import { FC, ReactElement, useState } from 'react';
import { message } from 'antd';
import Server from 'utils/serviceApi';
import styled from 'styled-components';
import { Button } from '@/components';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '@/hooks';


const Login: FC = (): ReactElement => {
    const [type, setType] = useState('password');
    const [account, setAccount] = useState('');
    const [pwd, setPwd] = useState('');
    const [captcha, setCaptcha] = useState('');
    const [uuid, setUuid] = useState(Date.now());
    const navigate = useNavigate();
    const [, getUserInfo] = useUserInfo();

    let login = async () => {
        try {
            Server.login({
                password: pwd,
                username: account,
                captcha,
                uuid,
            })
                .then(async (res: any) => {
                    if (res.code !== 0) {
                        message.warning(res.msg);
                    } else {
                        window.localStorage.setItem('token', res.data.token);
                        await getUserInfo();
                        message.success('login success');
                        navigate('/user');
                    }
                })
                .catch(e => {
                    console.error('login error', e?.message)
                });
        } catch (e) {
            console.log('login e----->', e);
        }
    };

    // let getUserInfo = async () => {
    //     try{
    //         const result:any = await Server.userinfo();
    //         if (result.code !== 0) throw new Error(result.msg);
    //         localStorage.setItem('userinfo', JSON.stringify(result.data))
    //         message.success('login success');
    //         navigate('/user');
    //     }catch(e:any){
    //         message.error(e.message);
    //     }
    // }
    return (
        <Main>
            <Content>
                <Top>
                    <Titlt>伽玛帐后台管理</Titlt>
                </Top>
                <ContMain>
                    <div className="line">
                        <i className="iconfont icon">&#xe601;</i>
                        <input type="text" placeholder="请输入账号" value={account} onChange={e => setAccount(e.target.value.trim())} />
                    </div>
                    <div className="line">
                        <i className="iconfont icon">&#xe601;</i>
                        <input type="text" placeholder="请输入验证码" value={captcha} onChange={e => setCaptcha(e.target.value.trim())} />
                        <img src={`${process.env.REACT_APP_URL}/captcha?uuid=${uuid}`} alt="" onClick={() => setUuid(Date.now())} />
                    </div>
                    <div className="line">
                        <i className="iconfont icon">&#xe649;</i>
                        <input type={type} placeholder="请输入密码" value={pwd} onChange={e => setPwd(e.target.value.trim())} />
                        {type === 'password' ? (
                            <i className="iconfont type" onClick={() => setType('text')}>
                                &#xe65f;
                            </i>
                        ) : (
                            <i className="iconfont type" onClick={() => setType('password')}>
                                &#xe60f;
                            </i>
                        )}
                    </div>
                    <Button style={{ backgroundColor: '#1890ff', color: '#fff', fontSize: '16px', width: '100%' }} onClick={login}>
                        Login
                    </Button>
                </ContMain>
            </Content>
            <Footer>
                <p>Copyright © 2023 Manage System</p>
            </Footer>
        </Main>
    );
};

const Main = styled.div`
    width: 100%;
    height: 100%;
    background: #f0f2f5;
    background-size: 100%;
    color: rgba(0, 0, 0, 0.65);
    top: 0;
    right: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Content = styled.div`
    width: 400px;
    min-height: 100%;
    //padding: 0px 0 144px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -200px;
`;
const Top = styled.div`
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
`;
const Titlt = styled.h3``;
const ContMain = styled.div`
    width: 400px;

    .line {
        height: 55px;
        padding: 0 16px;
        background: #fff;
        border-radius: 8px;
        flex-direction: row;
        margin-bottom: 22px;
        font-style: normal;
        font-size: 16px;
        display: flex;
        align-items: center;
        padding: 0 0px 0 50px;
        position: relative;
        i {
            color: rgb(38, 179, 160);
        }
        .icon {
            position: absolute;
            left: 15px;
            font-size: 20px;
        }
        .type {
            position: absolute;
            right: 15px;
            font-size: 20px;
            z-index: 9;
            cursor: pointer;
        }
        input {
            height: 100%;
            width: 100%;
            font-size: 16px;
            padding: 0 10px;
            border: none;
            background: none;
        }
    }
`;
const LoginBtn = styled.button`
    margin-top: 50px;
    font-size: 16px;
    height: 60px;
    width: 100%;
`;
const Footer = styled.div`
    position: absolute;
    width: 100%;
    bottom: 30px;
    padding: 0 16px;
    margin: 48px 0 0px;
    text-align: center;
`;

export default Login;
