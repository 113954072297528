import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import Server from 'utils/serviceApi';
import { AppState, useAppDispatch } from '@/state';
import { setUserInfo } from '@/state/base/actions';

function useUserInfo(): [Record<string, any>, () => Promise<void>] {
    const dispatch = useAppDispatch();
    const userInfo = useSelector<AppState, AppState['base']['userInfo']>((state: AppState) => state.base.userInfo);
    const getUserInfo = async (): Promise<void> => {
        try {
            const result: any = await Server.userinfo();
            if (result.code !== 0) throw new Error(result.msg);
            dispatch(setUserInfo(result.data));
            return result.data
        } catch (e: any) {
            message.error(e.message);
        }
    };
    useEffect(() => {
        console.log('token::::', localStorage.getItem('token'))
        if(localStorage.getItem('token')){
            getUserInfo()
        }
    }, []);
    return [userInfo, getUserInfo];
}

export default useUserInfo;
