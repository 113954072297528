import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';

import 'moment/locale/zh-cn';
import zhCN from 'antd/es/date-picker/locale/zh_CN';

// import zhCN from 'antd/locale/zh_CN';
import 'style/index.scss';
import 'utils/met';

import Entry from './entry';
import reportWebVitals from './reportWebVitals';
import { Scrollbars } from 'react-custom-scrollbars';

ReactDOM.render(
    <Scrollbars>
        <ConfigProvider locale={zhCN}>
            <Entry />
        </ConfigProvider>
    </Scrollbars>,
    document.getElementById('root')
);

reportWebVitals();
