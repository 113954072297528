import { FC, ReactElement, useEffect, useState } from 'react';
import Server from 'utils/serviceApi';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import { Button, message, Table } from 'antd';
import AddModal from './modal/add-modal';
import type { ColumnsType } from 'antd/es/table';
import { Form, Row, Col, Input, Select, DatePicker, Table, Button } from 'antd';

import { useDispatch } from 'react-redux';
const { Option } = Select;

interface DataType {
    _id: string;
    page: string;
    place: string;
    type: string;
    title: string;
    description: string;
    status: string;
    uuid: string;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 12 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const User: FC = (): ReactElement => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [placeList, setPlaceList] = useState([]);
    const [placeTypeList, setPlaceTypeList] = useState([]);
    const [addVisible, setAddVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [deleteLoad, setDeleteLoad] = useState(false);
    const [deleteDisabled, setDeleteDisabled] = useState(true);
    const [editInfo, setEditInfo] = useState({});

    const [totalSize, setTotalSize] = useState(10);

    const [search, setSearch] = useState({
        pageNo: 1,
        pageSize: 10,
    })

    const [form] = Form.useForm();

    const columns: ColumnsType<DataType> = [
        {
            title: 'Id',
            dataIndex: 'id',
        },
        {
            title: '用户名',
            dataIndex: 'username',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            render: (_, record: any) => <span className='_link' onClick={() => navigate(`/invite?invite=${record.id}`)}>{record.mobile}</span>,
        },
        {
            title: '邀请码',
            dataIndex: 'inviteCode',
        },
        // {
        //     title: '推荐人手机号',
        //     dataIndex: 'inviterMobile',
        // },
        {
            title: '身份',
            dataIndex: 'userRole',
        },
        {
            title: '黑名单',
            dataIndex: 'blacklist',
            render: (_, record: any) => <span>{record.blacklist === 0 ? '否' : '是'}</span>,
        },
        {
            title: '免费次数',
            dataIndex: 'freeTimes',
            render: (_, record: any) => <span>{record.freeTimes}次</span>,
        },
        {
            title: '会员',
            dataIndex: 'vip',
            render: (_, record: any) => <span>{record.vip ? '是' : '否'}</span>,
        },
        // {
        //     title: '会员截止日期',
        //     dataIndex: 'vipDeadline',
        //     render: (_, record: any) => <span>{record?.vipDeadline ?? '暂无开通'}</span>,
        // },
        {
            title: '操作',
            render: (_, record) => (
                <Button type="primary" onClick={() => handleEdit(record)}>
                    编辑
                </Button>
            ),
        },
    ];

    const getUserList = (_search = {}) => {
        setLoading(true);
        const params = {
            ...search,
            ..._search
        }
        Server.userList(params)
            .then((res: any) => {
                setSearch({...params})
                setPlaceList(res.data.list);
                setTotalSize(res.data.total);
            })
            .catch(e => {
                console.log('userList error======>', e);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCancel = isUpdate => {
        setAddVisible(false);
        setEditInfo({});
        if (isUpdate) {
            getUserList();
        }
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setDeleteDisabled(newSelectedRowKeys.length <= 0);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleEdit = item => {
        setEditInfo(item);
        setAddVisible(true);
    };

    const handleSearch = () => {
        const values = form.getFieldsValue();
        console.log('values:::', values)
        // setSearch({
        //     ...values,
        //     pageNo: 1
        // })
        getUserList({
            ...values,
            pageNo: 1
        })
    };

    useEffect(() => {
        getUserList();
    }, []);

    return (
        <Full>
            <Form size="large" form={form} name="advanced_search" className="ant-advanced-search-form">
                <Row gutter={24}>
                    <Col span={7}>
                        <Form.Item name="mobile" label="手机号">
                            <Input type="text" size="middle" placeholder="请输入手机号" />
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name="vip" label="会员">
                            <Select size="middle" placeholder="请选择会员状态" allowClear>
                                <Option value={1}>是</Option>
                                <Option value={0}>否</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item name="blacklist" label="黑名单">
                            <Select size="middle" placeholder="请选择黑名单状态" allowClear>
                                <Option value={1}>是</Option>
                                <Option value={0}>否</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        span={3}
                        style={{
                            textAlign: 'left',
                        }}
                    >
                        <Button type="primary" className="btn" size="middle" onClick={handleSearch}>
                            筛选
                        </Button>
                    </Col>
                </Row>

                
            </Form>

            <Table
                // rowSelection={rowSelection}
                rowKey="id"
                columns={columns}
                loading={loading}
                dataSource={placeList}
                pagination={{
                    hideOnSinglePage: false, // 只有一页时不显示分页
                    showTotal: () => `共 ${totalSize} 条`, // 显示总共有多少条
                    total: totalSize, // 总共有多少条
                    onChange: (page) => getUserList({pageNo: page}), // 页码变化回调函数
                    // showQuickJumper: true, // 是否显示快速跳转
                    // current: currentPage, // 当前active的页码
                    showSizeChanger: false,  // 是否显示页面大小变化选择框
                    // onShowSizeChange: pageSizeChange,  //页面大小变化回调函数
                    // pageSizeOptions: ["10", "20", "50"],  // 页面大小变化选择框中可选变化
                }}
            />
            <AddModal visible={addVisible} typeList={placeTypeList} initInfo={editInfo} handleCancel={handleCancel} />
        </Full>
    );
};

const Full = styled.div`
.ant-advanced-search-form{
    margin-bottom: 20px;
    .btn{
        position: relative;
        top: 4px;
    }
}
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    font-size: 20px;
    font-weight: 700;
`;

const DeleteBtn = styled(Button)`
    && {
        margin-bottom: 20px;
    }
`;

export default User;
