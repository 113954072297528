import React, { useEffect, useState, ReactElement } from 'react';
import LayoutMenu from '../menu';
import TopBar from '../topbar';
import styled from 'styled-components';
import { Button } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const Main = styled.div`
    width: 100%;
    height: 100%;
    background: #fdf2f245;
    min-width: 1200px;
    @media (max-width: 768px) {
        width: 100%;
        min-width: 100%;
    }
`;
const Cont = styled.div`
    height: calc(100vh - 70px);
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
        display: block;
    }
`;
const Mask = styled.div`
    width: 100%;
    height: 100%;
    display: none;
    @media (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.3);
    }
`;
const MenuView = styled.div.attrs((props:any)=> ({collapsed: props.collapsed, showMenu:props.showMenu}))`
    width: ${(props:any)=> props.collapsed ? '80px' : '210px'};
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    .collaps{
        width: ${(props:any)=> props.collapsed ? '80px' : '210px'};
        transition: width .3s cubic-bezier(0.215, 0.61, 0.355, 1);
        position: absolute;
        bottom: 0;
        left: 0;
        text-align: left;
    }
    @media (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        width: ${(props:any)=> props.showMenu ? '100%' : '0'};
        overflow-x: hidden;
        transition: width .2s;
        z-index: 999;
        .ant-menu{
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            position: relative;
            z-index: 9;
            transition: width .2s;
            font-size: 15px;
            
        }
        .ant-menu-root{
            width: ${(props:any)=> props.showMenu ? '50%' : '0'};
        }
        .ant-menu-sub{
            text-indent: 25px
        }
        .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
            height: 30px;
            line-height: 30px;
        }
        .ant-menu-item .ant-menu-item-icon, .ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-item .anticon, .ant-menu-submenu-title .anticon{
            font-size: 20px;
        }
        .ant-menu-item .ant-menu-item-icon + span, .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span{
            margin-left: 15px;
        }
        .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after{
            width: 30px;
            height: 7.5px;
            border-radius: 10px;
        }
        .ant-menu-submenu-expand-icon, .ant-menu-submenu-arrow{
            width: 10px;
            right: 50px;
            overflow: hidden;
        }
        .collaps{
            display: none
        }
    }
`;
const LayoutCont = styled.div`
    min-width: calc(100vw - 210px);
    height: 100%;
    padding: 15px 15px;
    flex: 1;
    @media (max-width: 768px) {
        min-width: 100px;
        padding: 15px 15px;
    }
`;
const SafeView = styled.section`
    border-radius: 10px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    background: #fff;
    padding: 15px 15px;
`;

const Layout: React.FC = ({ children }): ReactElement => {
    const [showMenu, setShowMenu] = useState<boolean>(false)
    const [collapsed, setCollapsed] = useState(false);
    const showMenuFN = (flag:boolean) => {
        console.log('flag')
    }
    return (
        <Main>
            <TopBar showMenu={showMenu} showMenuFN={setShowMenu} />
            <Cont>
                <MenuView collapsed={collapsed} showMenu={showMenu}>
                    <Mask onClick={()=> setShowMenu(false)}></Mask>
                    <LayoutMenu collapsed={collapsed} />
                    <Button className='collaps' type="primary" onClick={() => setCollapsed(!collapsed)}>
                        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                </MenuView>
                <LayoutCont>
                    <SafeView>{children}</SafeView>
                </LayoutCont>
            </Cont>
        </Main>
    );
};

export default Layout;
